import * as React from "react"
import { Icon } from "antd"
import styles from "./getStarted.module.scss"
import imageOne from "../../images/getStartedLady.jpg"
const GetStarted = props => {
  return (
    <section className={styles.getStartedContainer}>
      <div className={styles.getStartedSubContainer} data-container={"one"}>
        <img src={imageOne} alt="getStarted" />
      </div>
      <div className={styles.getStartedSubContainer} data-container={"two"}>
        <div className={styles.upperText}>
          <h1>Guide to Get Started</h1>
          <h5>How-to sign up as a driver</h5>
        </div>
        <div className={styles.lowerText}>
          <p>
            It's very simple to sign up on the CabraCabs. Watch the below video
            to know more.
          </p>
          <button className={styles.buttonVideo}>
            <Icon type="play-circle" style={{ fontSize: "1.67rem" }} />
            <label>Watch Video</label>
          </button>
        </div>
      </div>
    </section>
  )
}

export default GetStarted
