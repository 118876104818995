import * as React from "react"
import styles from "./driver.module.scss"
import { Icon } from "antd"
import GoogleLogin from "react-google-login"
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props"

import { StaticQuery, graphql } from "gatsby"

import DriverSectionTwo from "./driverSection2"
import DocumentsRequired from "./driverSection3"

const LoginButton = ({ onClick, disabled, text, icon, type }) => {
  return (
    <button
      onClick={onClick}
      disabled={disabled}
      data-button={type}
      className={styles.loginBtn}
    >
      {icon}
      <b>{text}</b>
    </button>
  )
}

const DriverPageContent = () => (
  <StaticQuery
    query={graphql`
      query SiteDriverPage {
        site {
          siteMetadata {
            GoogleClientID
            FacebookAppID
          }
        }
      }
    `}
    render={data => (
      <div className={styles.driverContent}>
        <h1>Sign up to drive with Cabra Cabs today!</h1>
        <p>Earn more, join for free, just 10% commission on rides.</p>
        <div className={styles.loginBtnContainer}>
          <GoogleLogin
            clientId={data.site.siteMetadata.GoogleClientID}
            buttonText="Sign up with Google"
            render={renderProps => (
              <LoginButton
                onClick={renderProps.onClick}
                disabled={renderProps.disabled}
                text={"Sign up with Google"}
                type="google"
                icon={<Icon type="google" style={{ fontSize: "1.5rem" }} />}
              />
            )}
            onSuccess={responseGoogle => console.log(responseGoogle)}
            onFailure={responseGoogle => console.log(responseGoogle)}
            cookiePolicy={"single_host_origin"}
          />
          <FacebookLogin
            appId={data.site.siteMetadata.FacebookAppID}
            autoLoad={false}
            render={renderProps => (
              <LoginButton
                onClick={renderProps.onClick}
                disabled={renderProps.disabled}
                text={"Sign up with Facebook"}
                type="facebook"
                icon={
                  <Icon
                    type="facebook"
                    theme={"filled"}
                    style={{ fontSize: "1.5rem" }}
                  />
                }
              />
            )}
            fields="name,email,picture"
            onClick={null}
            callback={responseFacebook => console.log(responseFacebook)}
          />
        </div>
      </div>
    )}
  />
)

const DriverPage = props => {
  return (
    <>
      <section className={styles.driverSection}>
        <div className={styles.driverContainer}>
          <div className={styles.driverContainerOverlay}>
            <DriverPageContent />
          </div>
        </div>
      </section>
      <DriverSectionTwo />
      <DocumentsRequired />
    </>
  )
}

export default DriverPage
