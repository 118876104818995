import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
// import Image from "../components/image"
import SEO from "../components/seo"
import DriverPage from "../components/driverPage/driver"
import GetStarted from "../components/getStarted/GetStarted"

const IndexPage = () => (
  <Layout>
    <SEO
      title="Home"
      keywords={[
        `gatsby`,
        `application`,
        `react`,
        `cabracabs`,
        `blockchaincabs`,
        `taxi`,
        `uk taxi`,
        `bkcc`,
      ]}
    />
    <DriverPage />
    <GetStarted />
  </Layout>
)

export default IndexPage
