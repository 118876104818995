import * as React from "react"
import { Collapse, Icon } from "antd"

import styles from "./driver.module.scss"
import imageOne from "../../images/appCar.jpg"
const Panel = Collapse.Panel

const customPanelStyle = {
  background: "#f7f7f7",
  borderRadius: 4,
  marginBottom: 24,
  border: 0,
  overflow: "hidden",
}

const DocumentsData = [
  {
    id: 1,
    header: "Vehicle Documents (Private Hire)",
    content: [
      { id: 1, text: "Private Hire Vehicle License (Paper Certificate)" },
      {
        id: 2,
        text:
          "Vehicle Insurance Certificate (Must contain name of the driver, otherwise Hire Agreement or Permission Letter)",
      },
      { id: 3, text: "MOT Certificate (or PHV plate with MOT Expiry Date)" },
      { id: 4, text: "Vehicle logbook or New Keeper’s slip" },
    ],
  },
  {
    id: 2,
    header: "Vehicle Documents (Metered Taxi)",
    content: [
      { id: 1, text: "Hackney Carriage Vehicle License (Paper License)" },
    ],
  },
  {
    id: 3,
    header: "Driver Documents (Private Hire)",
    content: [
      { id: 1, text: "DVLA driving License" },
      { id: 2, text: "Private Hire Drivers License (Paper or Badge)" },
    ],
  },
  {
    id: 4,
    header: "Driver Documents (Metered Taxi)",
    content: [
      { id: 1, text: "DVLA driving License" },
      { id: 2, text: "Hackney Carriage Driver’s License" },
    ],
  },
  {
    id: 5,
    header: "Payment Information",
    content: [
      { id: 1, text: "Bank Account and Sort code." },
      { id: 2, text: "National Insurance Number." },
      { id: 3, text: "Your address with postcode." },
      { id: 4, text: "Your email address." },
      { id: 5, text: "Your mobile number." },
      { id: 6, text: "Your full name." },
    ],
  },
  {
    id: 6,
    header: "Passport size photograph",
    content: [
      {
        id: 1,
        text:
          "Your photo will be shown to the rider when they book a ride with us in our App.",
      },
    ],
  },
]

const DocumentsRequired = () => {
  return (
    <section className={styles.sectionThreeContainer}>
      <div className={styles.upperHeaderSectionThree}>
        <h1>Documents required</h1>
        <h5>to start driving with CabraCabs</h5>
      </div>
      <div className={styles.lowerSectionThree}>
        <div className={styles.collapseSection}>
          <Collapse
            bordered={false}
            expandIcon={({ isActive }) =>
              isActive ? <Icon type="minus" /> : <Icon type="plus" />
            }
            className={styles.collapseContainer}
            defaultActiveKey={[""]}
          >
            {DocumentsData.map(dataObj => {
              return (
                <Panel
                  header={dataObj.header}
                  key={dataObj.id}
                  style={customPanelStyle}
                >
                  <ol>
                    {dataObj.content.map((contentListItem, i) => {
                      return <li key={i}>{contentListItem.text}</li>
                    })}
                  </ol>
                </Panel>
              )
            })}
          </Collapse>
        </div>

        <div className={styles.logoContainer}>
          <img src={imageOne} alt="appCarWithGPS" />
        </div>
      </div>
    </section>
  )
}

export default DocumentsRequired
