import * as React from "react"
import styles from "./driver.module.scss"

import logo_lowCommission from "../../images/low_commission.svg"
import logo_dailyPayments from "../../images/daily_payment.svg"
import logo_booking from "../../images/booking.svg"
import logo_sos from "../../images/sos.svg"
import logo_support from "../../images/support.svg"
import logo_workingHours from "../../images/working_hours.svg"

const LowerSectionData = [
  {
    id: "1",
    logo: logo_lowCommission,
    content: "Low commission & higer earnings",
  },
  {
    id: "2",
    logo: logo_dailyPayments,
    content: "Daily Payments",
  },
  {
    id: "3",
    logo: logo_booking,
    content: "Get a booking wherever you are headed",
  },
  {
    id: "4",
    logo: logo_sos,
    content: "SOS for your safety",
  },
  {
    id: "5",
    logo: logo_support,
    content: "24/7 helpline for your support",
  },
  {
    id: "6",
    logo: logo_workingHours,
    content: "Choose your own working hours",
  },
]

const CardItem = props => {
  return (
    <div className={styles.driverSectionTwoCardItem}>
      <img src={props.logo} alt={Math.random() + "CardImage"} />
      <p>{props.content}</p>
    </div>
  )
}
const DriverSectionTwo = props => {
  return (
    <section className={styles.sectionTwoContainer}>
      <div className={styles.upperHeaderSectionTwo}>
        <h1>Freedom of Work, Daily Payments</h1>
        <h5>are just a few reasons our drivers love us</h5>
      </div>

      <div className={styles.lowerSectionTwo}>
        {LowerSectionData.map(dataObj => {
          return (
            <CardItem
              content={dataObj.content}
              logo={dataObj.logo}
              key={dataObj.id}
            />
          )
        })}
      </div>
    </section>
  )
}

export default DriverSectionTwo
